<template>
  <div :class="element.name">
    <Article
      :legend="type"
      :title="headline"
      :src="image.value"
      :alt="image.altText"
      :href="link"
      :src-set="image.srcset"
      :custom-type="customType"
    />
  </div>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      headline() {
        return this.getValue("headline")
      },
      image() {
        return this.getIngredient("image")
      },
      link() {
        return this.getValue("link")
      },
      type() {
        return this.getValue("type")
      },
      customType() {
        return this.getValue("custom_type")
      },
    },
  }
</script>
